import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getHeading } from "../utils";

const MemberServices = ({ items, linkProfile }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  return (
    <div className="w-full w-max-[1300px] mx-auto py-4">
      <div className="flex flex-col justify-center items-center my-3 py-3">
        <h1>SERVICES</h1>
        <div
          style={{ width: 60 }}
          className="border-bottom border-4 border-success text-start"
        />
      </div>

      <div className="row">
        {items.map((item, i) => {
          return (
            <div
              key={i}
              className="relative flex col-md-6 my-2 cursor-pointer"
              onMouseEnter={() => {
                setItemSelected(item);

                setShowDetail(true);
              }}
              onMouseLeave={() => {
                setItemSelected({});

                setShowDetail(false);
              }}
            >
              <div className="flex flex-col justify-between content-between border border-width-2 p-2 h-[250px] rounded">
                <div className="p-2">
                  <h2>{item.exptitle}</h2>
                  {item.expabout}
                </div>
                <div className="p-2">
                  <div className="flex justify-between items-center">
                    <p>{item.exporg}</p>
                    <p>
                      {item.expfrom} - {item.expto}
                    </p>
                  </div>
                </div>
              </div>
              {showDetail && linkProfile && (
                <div className="absolute z-100 bg-gray-200 opacity-80 flex justify-center items-center top-0 bottom-0 left-0 right-0 p-3">
                  {linkProfile && (
                    <Link
                      className="text-decoration-none text-white cursor-pointer bg-success rounded p-2"
                      to={`/business-profile/${
                        itemSelected.expmemid
                      }/${itemSelected.expmemname
                        .toLowerCase()
                        .split(" ")
                        .join("-")}`}
                    >
                      Contact service provider
                    </Link>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MemberServices;
