import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import { Pagination } from "../components";
import moment from "moment";
import {
  FaStar,
  FaUser,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Tabs, Tab } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  author: Yup.string()
    .label("Name")
    .required()
    .min(5, "Must have at least 3 characters"),
  email: Yup.string()
    .email("Enter a valid email")
    .label("Your email")
    .required(),
  story: Yup.string().label("Your review").required(),
});

const UserReviews = ({ slug, section }) => {
  let navigate = useNavigate();

  const { apiUrl, userData, formatAmount } = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [stars, setStars] = useState(1);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [revloading, setRevLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [key, setKey] = useState("Reviews");

  useEffect(() => {
    getreviews();
  }, [slug, page, limit]);

  const getreviews = () => {
    return fetch(
      `${apiUrl}/api/reviews.php?action=getreviews&slug=${slug}&section=${section}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          setItems(res.rows);
          setFilteredItems(res.rows);
          setTotal(res.count);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }
  ) => {
    setRevLoading(true);

    let formData = new FormData();

    formData.append("author", values.author);
    formData.append("email", values.email);
    formData.append("stars", stars);
    formData.append("review", values.story);
    formData.append("slug", slug);
    formData.append("section", section);
    formData.append("username", userData?.username);
    formData.append("showemail", values.showemail ? 1 : 0);
    formData.append("action", "post_review");

    return fetch(`${apiUrl}/api/reviews.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setRevLoading(false);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setRevLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "review submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleNext = () => {
    if (currentIndex === filteredItems.length - 1)
      setCurrentIndex(filteredItems.length - 1);

    if (currentIndex < filteredItems.length - 1) {
      setCurrentIndex((curr) => curr + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) setCurrentIndex(0);
    if (currentIndex > 0) {
      setCurrentIndex((curr) => curr - 1);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  const getArray = (rating) => {
    let arr = [];
    for (let i = 0; i < rating; i++) {
      arr.push(i);
    }
    return arr;
  };

  const balanceArray = (num) => {
    const bnum = 5 - num;
    let barray = [];

    for (let i = 1; i <= bnum; i++) {
      barray.push(i);
    }
    return barray;
  };

  return (
    <div className="relative w-full w-max-[1300px] mx-auto py-3">
      <div className="row bg-blue-100 px-3">
        <div className="flex flex-col justify-center items-center my-3 py-3">
          <h1>REVIEWS</h1>
          <div
            style={{ width: 60 }}
            className="border-bottom border-4 border-success text-start"
          />
        </div>

        <div className="my-2 d-flex justify-content-between">
          <div className="align-items-center">
            {msg !== "" ? (
              <div className="p-2">
                <span className="p-2 border border-info text-dark">{msg}</span>
              </div>
            ) : null}
            {errorMsg !== "" ? (
              <div className="p-2">
                <span className="p-2 border border-info text-dark">
                  {errorMsg}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <Tabs defaultActiveKey={key} onSelect={(k) => setKey(k)}>
          <Tab eventKey="Reviews" title="Reviews">
            {items.length > 0 ? (
              <div className="mb-2 pb-2">
                <h6 className="px-3 small my-3">
                  {total} Reviews{parseInt(total) === 1 ? "" : "s"}
                </h6>

                <div className="row">
                  {filteredItems
                    .slice(currentIndex, currentIndex + 2)
                    .map((item) => {
                      return (
                        <div
                          className="relative h-[400px] my-3 py-3 col-lg-6 col-12"
                          key={item.id_ad}
                        >
                          <div className="h-full bg-white rounded flex flex-col justify-between items-center p-2">
                            <div className="absolute p-2 h-24 w-24 top-[-40px] rounded-full bg-gray-400">
                              <FaUser
                                className="bg-gray-100 h-20 w-20 rounded-full"
                                size={30}
                              />
                            </div>
                            <div className="absolute top-[70px] flex flex-col justify-center items-center">
                              <div className="flex">
                                {getArray(item.rev_stars).map((item, i) => {
                                  return (
                                    <span className="p-1" key={i}>
                                      <FaStar color="brown" size={18} />
                                    </span>
                                  );
                                })}
                                {balanceArray(item.rev_stars).map((item, i) => {
                                  return (
                                    <span className="p-1" key={i}>
                                      <FaStar color="gray" size={18} />
                                    </span>
                                  );
                                })}
                              </div>
                              <div className="px-5 py-3">{item.review}</div>
                            </div>
                            <div className="absolute bottom-[50px] flex flex-col justify-center items-center">
                              <div className="h6">{item.rev_name}</div>
                              <div className="w-[300px] flex justify-between items-center">
                                <p className="text-xs">
                                  {parseInt(item.rev_showemail) === 1 &&
                                    `(${item.rev_email})`}
                                </p>
                                <p className="text-xs">
                                  {moment(item.rev_timeposted).format(
                                    "Do, MMM YYYY"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="flex justify-center items-center my-2 py-2">
                  <FaChevronCircleLeft
                    onClick={() => handlePrev()}
                    size={40}
                    className="bg-white rounded-full cursor-pointer p-1"
                  />
                  <FaChevronCircleRight
                    onClick={() => handleNext()}
                    size={40}
                    className="bg-white rounded-full cursor-pointer ml-4 p-1"
                  />
                </div>

                {items.length > 0 && (
                  <Pagination
                    limit={limit}
                    page={page}
                    total={total}
                    cangoBack={cangoBack}
                    cangoForward={cangoForward}
                    handleChange={handleChange}
                    doNext={doNext}
                    doPrevious={doPrevious}
                  />
                )}
              </div>
            ) : (
              <p>No reviews posted yet.</p>
            )}
          </Tab>
          <Tab eventKey="Post review" title="Post review">
            <Formik
              initialValues={{
                author: userData ? userData.name : "",
                email: userData ? userData.email : "",
                story: "",
                showemail: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, { setSubmitting, resetForm });
              }}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isValid,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <div className="my-3">
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className="row my-2 py-3">
                      <div className="col-md-8">
                        {!userData && (
                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="author">Name</label>
                            </div>
                            <Field
                              id="author"
                              name="author"
                              value={values.author}
                              onChangeText={handleChange("author")}
                              placeholder="Name"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("author")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.author && errors.author && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.author && errors.author}
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        {!userData && (
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="email">Email address</label>
                            </div>
                            <Field
                              id="email"
                              name="email"
                              value={values.email}
                              onChangeText={handleChange("email")}
                              placeholder="Email address"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("email")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.email && errors.email && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.email && errors.email}
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="form-group my-1">
                          <div className="py-1 my-1 flex">
                            <label htmlFor="stars">Rate</label>
                            {[1, 2, 3, 4, 5].map((item, i) => {
                              return (
                                <span
                                  className="p-1"
                                  key={i}
                                  onClick={() => setStars(item)}
                                >
                                  <FaStar size={18} />
                                </span>
                              );
                            })}
                          </div>
                          <div className="flex">
                            Your rating
                            {getArray(stars).map((item, i) => {
                              return (
                                <span className="p-1" key={i}>
                                  <FaStar color="brown" size={18} />
                                </span>
                              );
                            })}
                            {balanceArray(stars).map((item, i) => {
                              return (
                                <span className="p-1" key={i}>
                                  <FaStar color="gray" size={18} />
                                </span>
                              );
                            })}
                          </div>
                        </div>

                        <div className="form-group my-1">
                          <div className="py-1">
                            <label htmlFor="review">
                              {userData
                                ? `Posting review as ${userData.name}`
                                : "review"}
                            </label>
                          </div>
                          <Field
                            as="textarea"
                            id="story"
                            name="story"
                            value={values.story}
                            onChangeText={handleChange("story")}
                            placeholderTextColor="#999"
                            onBlur={handleBlur("story")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.story && errors.story && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.story && errors.story}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1 py-2">
                          <div>
                            <label htmlFor="showemail">Display email?</label>
                            <Field
                              type="checkbox"
                              name="showemail"
                              className="mx-2"
                            />{" "}
                            {values.showemail ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      buttonType="outline"
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting || revloading}
                      loading={isSubmitting}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>

                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className="btn btn-secondary mx-2"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              )}
            </Formik>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default UserReviews;
