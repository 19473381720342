import React, { useState, useEffect, useContext, useRef } from "react";
import {
  AppLayout,
  NavBar,
  MemberServices,
  MemberPortfolio,
  MemberProducts,
  MemberProfile,
  MemberContact,
  UserReviews,
} from "../components";
import { useParams } from "react-router-dom";
import { SiteContext } from "../context";
import Seo from "./Seo";
import { InlineShareButtons } from "sharethis-reactjs";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import QRCode, { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

const BusinessProfile = () => {
  let { id, member } = useParams();

  const ref = useRef(null);
  const refService = useRef(null);
  const refPortfolio = useRef(null);
  const refProduct = useRef(null);
  const refReview = useRef(null);
  const refContact = useRef(null);

  const { apiUrl, formatBalance, slugify, siteconfig, setUserData } =
    useContext(SiteContext);

  const [profile, setProfile] = useState(null);
  const [services, setServices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getProfile();
  }, [id]);

  const getProfile = () => {
    return fetch(`${apiUrl}/api/business_profile.php?action=fetch&user=${id}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setProfile(res.user[0]);
          setServices(res.services);
          setProjects(res.projects);
          setProducts(res.products);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doSanitize = (item) => {
    return item.replace(/<p>/g, "").replace(/<\/p>/g, " ");
  };

  const downloadQR = () => {
    const canvas = document.getElementById("myqrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${member}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (errorMsg !== "") {
    return <div className="my-3 p-3">{errorMsg}</div>;
  }

  return (
    <div>
      {profile && (
        <Seo
          title={`${profile.name} | ${siteconfig.shopname}`}
          description={profile.profile}
          url={`${apiUrl}/api/profile_detail.php?id=${id}&name=${member}`}
          img={`${apiUrl}/${profile.photo}`}
        />
      )}

      <NavBar />
      <div className="row">
        <div className="hidden md:block col-md-2 bg-blue-100">
          {profile && (
            <div className="fixed h-full flex flex-col justify-start items-center my-3">
              <img
                src={`${apiUrl}/${profile.photo}`}
                height={150}
                width={150}
                className="rounded-full p-2"
                alt="picture"
              />

              <button
                className="py-1 my-1"
                onClick={() =>
                  ref.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                PROFILE
              </button>
              <button
                className="py-1 my-1"
                onClick={() =>
                  refService.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                SERVICES
              </button>
              <button
                className="py-1 my-1"
                onClick={() =>
                  refPortfolio.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                PORTFOLIO
              </button>
              <button
                className="py-1 my-1"
                onClick={() =>
                  refReview.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                REVIEWS
              </button>
              <button
                className="py-1 my-1"
                onClick={() =>
                  refProduct.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                PRODUCTS
              </button>
              <button
                className="py-1 my-1"
                onClick={() =>
                  refContact.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                CONTACT
              </button>
            </div>
          )}
        </div>
        <div className="relative col-md-10">
          <div ref={ref}>
            {/* <MemberProfile apiUrl={apiUrl} item={profile} /> */}
            {profile && (
              <div className="my-3 py-2">
                <div className="static w-full w-max-[1300px] mx-auto">
                  <div className="row bg-gray-100 h-screen">
                    <div className="col-md-7 p-5">
                      <h1 className="my-2 py-2 font-bold uppercase">
                        {profile.name}
                      </h1>
                      <p className="text-lg">
                        {profile.profile.split(" ", 50).join(" ")}
                      </p>

                      <div className="flex my-3">
                        {profile.facebook_link !== "" &&
                          profile.facebook_link !== null &&
                          profile.facebook_link !== undefined && (
                            <div className="h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                              <a
                                className="h-13 w-13"
                                href={`${profile.facebook_link}`}
                                target="_blank"
                              >
                                <FaFacebook size={30} color="#fff" />
                              </a>
                            </div>
                          )}
                        {profile.twitter_link !== "" &&
                          profile.twitter_link !== null &&
                          profile.twitter_link !== undefined && (
                            <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                              <a
                                className="h-13 w-13"
                                href={`${profile.twitter_link}`}
                                target="_blank"
                              >
                                <FaTwitter size={30} color="#fff" />
                              </a>
                            </div>
                          )}
                        {profile.instagram_link !== "" &&
                          profile.instagram_link !== null &&
                          profile.instagram_link !== undefined && (
                            <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                              <a
                                className="h-13 w-13"
                                href={`${profile.instagram_link}`}
                                target="_blank"
                              >
                                <FaInstagram size={30} color="#fff" />
                              </a>
                            </div>
                          )}
                        {profile.linkedin_link !== "" &&
                          profile.linkedin_link !== null &&
                          profile.linkedin_link !== undefined && (
                            <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                              <a
                                className="h-13 w-13"
                                href={`${profile.linkedin_link}`}
                                target="_blank"
                              >
                                <FaLinkedin size={30} color="#fff" />
                              </a>
                            </div>
                          )}
                        {profile.youtube_link !== "" &&
                          profile.youtube_link !== null &&
                          profile.youtube_link !== undefined && (
                            <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                              <a
                                className="h-13 w-13"
                                href={`${profile.youtube_link}`}
                                target="_blank"
                              >
                                <FaYoutube size={30} color="#fff" />
                              </a>
                            </div>
                          )}
                      </div>
                      <InlineShareButtons
                        config={{
                          alignment: "left", // alignment of buttons (left, center, right)
                          color: "white", // set the color of buttons (social, white)
                          enabled: true, // show/hide buttons (true, false)
                          font_size: 16, // font size for the buttons
                          labels: "cta", // button labels (cta, counts, null)
                          language: "en", // which language to use (see LANGUAGES)
                          networks: [
                            // which networks to include (see SHARING NETWORKS)
                            "facebook",
                            "twitter",
                            "email",
                            "linkedin",
                            "whatsapp",
                            "messenger",
                          ],
                          padding: 12, // padding within buttons (INTEGER)
                          radius: 10, // the corner radius on each button (INTEGER)
                          show_total: false,
                          size: 40, // the size of each button (INTEGER)

                          // OPTIONAL PARAMETERS

                          url: `${apiUrl}/api/profile_detail.php?id=${id}&name=${member}`,
                          image: `${apiUrl}/${profile.photo}`, // (defaults to og:image or twitter:image)
                          description: doSanitize(profile.profile)
                            .split(" ", 100)
                            .join(" "), // (defaults to og:description or twitter:description)
                          title: profile.name, // (defaults to og:title or twitter:title)
                          message: doSanitize(profile.profile)
                            .split(" ", 120)
                            .join(" "), // (only for email sharing)
                          subject: profile.name, // (only for email sharing)
                          username: "CSSA89Class", // (only for twitter sharing)
                        }}
                      />
                      <div className="my-1 py-1">
                        <QRCodeCanvas
                          id="myqrcode"
                          value={`${apiUrl}/business-profile/${
                            profile.id
                          }/${profile.name.toLowerCase().split(" ").join("-")}`}
                        />
                        <div className="py-2 my-1">
                          <a
                            className="bg-gray-300 cursor-pointer px-2 py-1 rounded text-dark text-decoration-none"
                            onClick={downloadQR}
                          >
                            Download QR
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="md:hidden">
                        <div className="absolute w-[120px] h-[120px] top-[5px] right-[20px]">
                          <img
                            src={`${apiUrl}/${profile.photo}`}
                            height={150}
                            width={150}
                            className="rounded-full p-2"
                            alt="picture"
                          />
                        </div>
                      </div>

                      <div className="absolute hidden md:block">
                        {profile.full_photo !== "" &&
                          profile.full_photo !== "NULL" &&
                          profile.full_photo !== undefined &&
                          profile.full_photo !== null && (
                            <div className="w-full h-full flex justify-end items-center top-[5px] right-[10px]">
                              <img
                                src={`${apiUrl}/${profile.full_photo}`}
                                height="100%"
                                width="100%"
                                className="opacity-20 md:opacity-90 rounded p-1"
                                alt="picture"
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {services.length > 0 && (
            <div ref={refService} className="p-3">
              <MemberServices items={services} linkProfile={false} />
            </div>
          )}

          {projects.length > 0 && (
            <div ref={refPortfolio} className="py-3">
              <MemberPortfolio
                apiUrl={apiUrl}
                items={projects}
                linkProfile={false}
              />
            </div>
          )}

          {products.length > 0 && (
            <div ref={refProduct} className="p-3">
              <MemberProducts
                apiUrl={apiUrl}
                formatBalance={formatBalance}
                items={products}
                linkProfile={false}
              />
            </div>
          )}

          <div ref={refReview} className="p-3">
            <UserReviews
              slug={`/business-profile/${id}/${member}`}
              section="Portfolio"
            />
          </div>

          <div ref={refContact} className="p-3 bg-gray-100">
            <MemberContact apiUrl={apiUrl} item={profile} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
