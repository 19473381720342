import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  title: Yup.string().label("Title").required(),
  about: Yup.string().label("Description").required(),
});

const UserProfileExperience = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [items, setItems] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getExperiences();
  }, [items]);

  const getExperiences = () => {
    let formData = new FormData();

    formData.append("username", userData && userData.username);
    formData.append("action", "fetchalluser");

    fetch(`${apiUrl}/api/profile_experience.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("title", values.title);
    formData.append("about", values.about);
    formData.append("org", values.org);
    formData.append("years", values.years);
    formData.append("from", values.from);
    formData.append("to", values.to);
    formData.append("mid", userData && userData.id);
    formData.append("mname", userData && userData.name);
    formData.append("username", userData && userData.username);
    formData.append("role", userData && userData.role);
    formData.append("id", values.id);
    formData.append("action", userAction);

    const sendType = "post";

    return fetch(`${apiUrl}/api/profile_experience.php`, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getExperiences();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 30000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setUploadedPic(null);
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    setShowModal(true);
  };

  const handleDelete = (item) => {
    setItemSelected(item);
    setShowDeleteModal(true);
  };

  const doDelete = () => {
    let formData = new FormData();

    formData.append("id", itemSelected.expid);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/profile_experience.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getExperiences();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doDelete()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "10%",
            right: isMobile ? 0 : "10%",
            width: isMobile ? "100%" : "80%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem ? updateItem.expid : "",
              member: updateItem ? updateItem.expmemid : "",
              title: updateItem ? updateItem.exptitle : "",
              about: updateItem ? updateItem.expabout : "",
              org: updateItem ? updateItem.exporg : "",
              years: updateItem ? updateItem.expyears : "",
              from: updateItem ? updateItem.expfrom : "",
              to: updateItem ? updateItem.expto : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="container my-2">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="member">Member</label>
                      </div>
                      {userAction === "update" && (
                        <div>{updateItem.expmemname} </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="title">Title</label>
                      </div>
                      <Field
                        id="title"
                        name="title"
                        value={values.title}
                        placeholder="Project title"
                        onBlur={handleBlur("title")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.title && errors.title && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.title && errors.title}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="about">Description</label>
                      </div>
                      <Field
                        multiline
                        id="about"
                        name="about"
                        value={values.about}
                        placeholder="About document"
                        onBlur={handleBlur("about")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.about && errors.about && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.about && errors.about}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="org">Organisation</label>
                      </div>
                      <Field
                        id="org"
                        name="org"
                        value={values.org}
                        placeholder="org"
                        onBlur={handleBlur("org")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.org && errors.org && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.org && errors.org}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="years">Number of years</label>
                      </div>
                      <Field
                        multiline
                        id="years"
                        name="years"
                        value={values.years}
                        placeholder="years"
                        onBlur={handleBlur("years")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.years && errors.years && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.years && errors.years}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="from">From</label>
                          </div>
                          <Field
                            multiline
                            id="from"
                            name="from"
                            value={values.from}
                            placeholder="from"
                            onBlur={handleBlur("from")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />

                          {touched.from && errors.from && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.from && errors.from}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="to">To</label>
                          </div>
                          <Field
                            multiline
                            id="to"
                            name="to"
                            value={values.to}
                            placeholder="to"
                            onBlur={handleBlur("to")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />

                          {touched.to && errors.to && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.to && errors.to}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title={userAction}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary capitalize"
                  >
                    {userAction}
                  </button>

                  <button
                    onClick={() => setShowModal(false)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>User Profile Services</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-3">Experience</div>
                    <div className="col-3">Organisation</div>
                    <div className="col-3">User</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-3">
                          {item.exptitle} ({item.expyears})
                        </div>
                        <div className="col-3">
                          {item.exporg}{" "}
                          <p>
                            {item.expfrom} - {item.expto}
                          </p>
                        </div>
                        <div className="col-3">{item.expmemname}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(UserProfileExperience);
