import React, { createContext, useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PRO;
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [pages, setPages] = useState([]);
  const [homeNews, setHomeNews] = useState([]);
  const [homeEvents, setHomeEvents] = useState([]);
  const [homeBlog, setHomeBlog] = useState([]);
  const [homeProducts, setHomeProducts] = useState([]);
  const [homeTestimonials, setHomeTestimonials] = useState([]);
  const [allBanners, setAllBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [fbAccessToken, setFbAccesstoken] = useState(null);
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [loading, setLoading] = useState(false);
  const singleProduct = true;
  const maxSiteWidth = "1300px";

  useEffect(() => {
    getUserDetails();
    getSiteConfig();
    getAllBanners();
    getNewsData();
    getBlogData();
    getEventsData();
  }, []);

  const getNewsData = () => {
    return fetch(`${apiUrl}/api/fetch_home_news.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomeNews(res.news);
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getBlogData = () => {
    return fetch(`${apiUrl}/api/fetch_home_blog.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomeBlog(res.blog);
        } else if (res.status === 400) {
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getEventsData = () => {
    return fetch(`${apiUrl}/api/fetch_home_events.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomeEvents(res.events);
        } else if (res.status === 400) {
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getAllBanners = () => {
    return fetch(`${apiUrl}api/bannerhorizontal.php`)
      .then(processResponse)
      .then((res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          const result = data;
          setAllBanners(result[0].banners);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const setAccessToken = (token) => {
    setFbAccesstoken(token);
  };

  const resetAccessToken = () => {
    setFbAccesstoken(null);
  };

  // const getFromAsync = async () => {
  //   try {
  //     const storedData = await localStorage.getItem("storedData");
  //     const parsedData = JSON.parse(storedData);

  //     if (storedData !== null) {
  //       await setHomeNews(parsedData.news);
  //       await setHomeEvents(parsedData.events);
  //       await setHomeBlog(parsedData.blog);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const setToAsync = async (value) => {
  //   try {
  //     if (value !== null) {
  //       const storeData = JSON.stringify(value);
  //       await localStorage.setItem("storedData", storeData);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setPages(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (res) => {
        setSiteconfig(res.config);
        setHomeTestimonials(res.testimonials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  const processResponse = (response) => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));
  };

  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem("user");
    const parsedVUser = JSON.parse(fromStorageUser);
    if (fromStorageUser !== null) {
      await setUserData(parsedVUser);
      await setIsLoggedOn(true);
    }
  };

  const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await setAccessToken(null);
    await localStorage.removeItem("user");
    await localStorage.removeItem("loggedin", false);
  };

  const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem("user", storedUser);
    await localStorage.setItem("loggedin", true);
  };

  const formatNumberK = (num) => {
    if (num > 0) {
      let numprice = num / 1000;

      let num = numprice.toString();
      return `${num}`;
    } else {
      return "0";
    }
  };

  const formatAmount = (price) => {
    if (price > 0) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return `N${formatter.format(price)}`;
    } else {
      return `N0.00`;
    }
  };

  const formatTotalAmount = (price) => {
    if (price > 0) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return `N${formatter.format(price)}`;
    } else {
      return `N0.00`;
    }
  };

  // const formatBalance = (price) => {
  //   let num;
  //   let thesign = price > 0 ? "" : "-";

  //   if (price > 0) {
  //     num = price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //     return `${thesign}N${num}`;
  //   } else {
  //     return `${thesign}N0.00`;
  //   }
  // };

  const formatBalance = (price) => {
    let num, thesign, thenumber;

    thesign = price < 0 ? "-" : "";
    thenumber = price < 0 ? price * -1 : price;

    if (thenumber > 0) {
      num = thenumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return price < 0 ? `N${num}.00` : `N${num}`;
    } else {
      return `N0.00`;
    }
  };

  const formatAmountK = (price) => {
    if (price > 0) {
      let num, numprice;

      numprice = price / 1000;
      num = `${numprice.toString()}`;

      return `${num}k`;
    } else {
      return "-";
    }
  };

  const contextValue = useMemo(() => {
    return {
      apiUrl,
      categories,
      pages,
      homeNews,
      homeEvents,
      homeTestimonials,
      homeProducts,
      homeBlog,
      allBanners,
      msg,
      isLoggedOn,
      userData,
      userToken,
      siteconfig,
      maxSiteWidth,
      isMobile,
      fbAccessToken,
      formatNumberK,
      slugify,
      handleSignOut,
      handleSignIn,
      setUserData,
      getServices,
      setAccessToken,
      resetAccessToken,
      formatAmount,
      formatAmountK,
      formatBalance,
      formatTotalAmount,
    };
  }, [
    homeNews,
    allBanners,
    isMobile,
    isLoggedOn,
    homeEvents,
    siteconfig,
    fbAccessToken,
  ]);

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
