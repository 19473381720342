import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";

const BannerTop = () => {
  let navigate = useNavigate();

  const { apiUrl, formatAmount } = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = () => {
    fetch(`${apiUrl}/api/bannerads.php?action=getbanner&ty=top`, {
      method: "get",
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          setItems(res.bannerads);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div style={{ overflow: "hidden" }} className="container shadow">
      {items && items.length > 0 && (
        <div className="mb-2 pb-2">
          <div className="text-muted small text-end">ADVERTISEMENT</div>

          {items.map((item) => {
            return (
              <div className="py-2" key={item.id_ad}>
                <div className="px-1 d-block d-md-none">
                  <div className="flex justify-center items-center">
                    {item.url_ad === "" ? (
                      <img
                        style={{ objectFit: "cover" }}
                        className="p-2"
                        src={`${apiUrl}/${item.file_ad}`}
                        height={90}
                        width={`100%`}
                      />
                    ) : (
                      <a href={`${item.url_ad}`} target="_blank">
                        {" "}
                        <img
                          style={{ objectFit: "cover" }}
                          className="p-2"
                          src={`${apiUrl}/${item.file_ad}`}
                          height={90}
                          width={`100%`}
                        />
                      </a>
                    )}
                  </div>
                </div>
                <div className="px-1 d-none d-md-block w-[80%] mx-auto flex justify-center content-center">
                  {item.url_ad === "" ? (
                    <img
                      style={{ objectFit: "contain" }}
                      className="p-2"
                      src={`${apiUrl}/${item.file_ad}`}
                      height={100}
                      width={`100%`}
                    />
                  ) : (
                    <a href={`${item.url_ad}`} target="_blank">
                      <img
                        style={{ objectFit: "contain" }}
                        className="p-2"
                        src={`${apiUrl}/${item.file_ad}`}
                        height={100}
                        width={`100%`}
                      />
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BannerTop;
