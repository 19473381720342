import React, { useState, useEffect, useContext } from "react";
import {
  AppLayout,
  MemberServices,
  MemberPortfolio,
  MemberProducts,
  Spinner,
} from "../components";
import { portflioCategories } from "../constants";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { SiteContext } from "../context";

const NetWorking = () => {
  const { apiUrl, formatBalance, setUserData } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [updateItem, setUpdateItem] = useState({});
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [services, setServices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, []);

  const getUsers = () => {
    fetch(`${apiUrl}/api/networking.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setMembers(res.rows);
          setFilteredMembers(res.rows);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCat = (cat) => {
    setSelectedCat(cat);
    getServices(cat);
  };

  const getServices = (cat) => {
    setServices([]);
    setProjects([]);
    setProducts([]);

    let formData = new FormData();

    formData.append("cat", cat);
    formData.append("action", "fetchcat");

    setLoading(true);

    fetch(`${apiUrl}/api/networking.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setServices(res.services);
          setProjects(res.projects);
          setProducts(res.products);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userProfilePic = (id) => {
    let user = members.filter((item) => item.id === id);

    if (user.length > 0) {
      return (
        <img
          className="h-[150px] w-[150px] rounded-full"
          src={`${apiUrl}/${user[0].photo}`}
          alt={user[0].name}
        />
      );
    }
  };

  return (
    <div>
      <div className="container py-3 my-1">
        <div className="w-full max-w-[1300px] flex bg-gray-300 py-2 justify-start items-center overflow-x-scroll">
          {/* <h5 className="bg-green-700 p-2 text-white">Category</h5> */}
          {portflioCategories.map((item, i) => {
            return (
              <button
                className={
                  item.name === selectedCat
                    ? "cursor-pointer p-1 rounded bg-gray-500 m-1 text-white"
                    : "cursor-pointer p-1 rounded bg-gray-100 m-1 text-dark"
                }
                key={i}
                onClick={() => handleCat(item.name)}
              >
                <span className="text-nowrap">{item.name}</span>
              </button>
            );
          })}
        </div>
        <div className="container my-2">
          {msg !== "" ? (
            <div className="my-4">
              <span className="p-2 my-2 border border-info text-dark">
                {msg}
              </span>
            </div>
          ) : null}
          {errorMsg !== "" ? (
            <div className="my-4">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}

          <div>
            Here is where you find members that provide different types of
            services and products. Members have portfolios so you get to see
            what they have done for other customers in the past
          </div>

          {loading && (
            <Spinner message="We are getting information based on the category you selected..." />
          )}

          <div>
            {services.length > 0 && (
              <div className="p-3">
                <div className="flex flex-col justify-center items-center my-3 py-3">
                  <h3>SERVICES</h3>
                  <div
                    style={{ width: 60 }}
                    className="border-bottom border-3 border-success text-start"
                  />
                </div>
                <div className="flex justify-start items-center text-decoration-none text-dark">
                  {services.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="text-decoration-none text-dark m-1 p-1"
                        to={`/business-profile/${
                          item.expmemid
                        }/${item.expmemname
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                      >
                        <div className="text-center">
                          {userProfilePic(item?.expmemid)}
                          {item.expmemname}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}

            {projects.length > 0 && (
              <div className="py-3">
                <div className="flex flex-col justify-center items-center my-3 py-3">
                  <h3>PORTFOLIO</h3>
                  <div
                    style={{ width: 60 }}
                    className="border-bottom border-3 border-success text-start"
                  />
                </div>
                <div className="flex justify-start items-center text-decoration-none text-dark">
                  {projects.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="text-decoration-none text-dark m-1 p-1"
                        to={`/business-profile/${
                          item.portmemid
                        }/${item.portmemname
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                      >
                        <div className="text-center">
                          {userProfilePic(item?.portmemid)}
                          {item.portmemname}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}

            {products.length > 0 && (
              <div className="p-3">
                <div className="flex flex-col justify-center items-center my-3 py-3">
                  <h3>PRODUCTS</h3>
                  <div
                    style={{ width: 60 }}
                    className="border-bottom border-3 border-success text-start"
                  />
                </div>
                <div className="flex justify-start items-center text-decoration-none text-dark">
                  {products.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="text-decoration-none text-dark m-1 p-1"
                        to={`/business-profile/${
                          item.prodmemid
                        }/${item.prodmemname
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                      >
                        <div className="text-center">
                          {userProfilePic(item?.prodmemid)}
                          {item.prodmemname}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(NetWorking);
