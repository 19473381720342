import React, { useState, useEffect, useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label("Username")
    .required()
    .min(4, "Must have at least 4 characters"),
  fullname: Yup.string()
    .label("Full name")
    .required()
    .min(5, "Must have at least 5 characters"),
  phone: Yup.string()
    .label("Mobile phone")
    .required()
    .min(11, "Must have at least 11 characters")
    .max(14, "Must have a maximum of 14 characters"),
  profile: Yup.string().label("Profile").required(),
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
});

const UpdateSignupForm = ({ handleSubmit, updateItem, setShowModal }) => {
  const { apiUrl } = useContext(SiteContext);

  const [uploadedPic, setUploadedPic] = useState(null);
  const [uploadedPicFull, setUploadedPicFull] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    updateItem.dob !== null
      ? setBirthDate(new Date(updateItem.dob))
      : setBirthDate(new Date());
  }, []);

  return (
    <div>
      <div className="container">
        {errorMsg !== "" ? (
          <span className="p-2 my-2 border border-danger text-danger">
            {errorMsg}
          </span>
        ) : null}

        <Formik
          initialValues={{
            id: updateItem.id ? updateItem.id : "",
            username: updateItem.username ? updateItem.username : "",
            fullname: updateItem.name ? updateItem.name : "",
            phone: updateItem.phone ? updateItem.phone : "",
            photo: updateItem.photo ? updateItem.photo : "",
            photofull: updateItem.full_photo ? updateItem.full_photo : "",
            profile: updateItem.profile ? updateItem.profile : "",
            email: updateItem.email ? updateItem.email : "",
            role: updateItem.role ? updateItem.role : "",
            facebooklink: updateItem.facebook_link
              ? updateItem.facebook_link
              : "",
            twitterlink: updateItem.twitter_link ? updateItem.twitter_link : "",
            instagramlink: updateItem.instagram_link
              ? updateItem.instagram_link
              : "",
            linkedinlink: updateItem.linkedin_link
              ? updateItem.linkedin_link
              : "",
            youtubelink: updateItem.youtube_link ? updateItem.youtube_link : "",
            residence: updateItem.location_country
              ? updateItem.location_country
              : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, {
              setSubmitting,
              resetForm,
              setShowModal,
              birthDate,
            });
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-2">
                    <div>
                      <label htmlFor="fullname">Full name</label>
                    </div>
                    <Field
                      disabled
                      id="fullname"
                      name="fullname"
                      value={values.fullname}
                      placeholder="Full name"
                      onBlur={handleBlur("fullname")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.fullname && errors.fullname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.fullname && errors.fullname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="email">Email</label>
                    </div>
                    <Field
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Your email"
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.email && errors.email && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="phone">Phone</label>
                    </div>
                    <Field
                      id="phone"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onBlur={handleBlur("phone")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.phone && errors.phone && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.phone && errors.phone}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="dob">
                        Date of birth{" "}
                        <span className="text-xs">
                          (For birthday notifications)
                        </span>
                      </label>
                    </div>
                    <DatePicker
                      dateFormat={"yyyy-MM-dd"}
                      selected={birthDate}
                      onChange={(date) => {
                        setBirthDate(date);
                      }}
                      className="border p-2"
                    />
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="desc">Profile</label>
                    </div>
                    <Field
                      as="textarea"
                      id="profile"
                      name="profile"
                      value={values.profile}
                      placeholder="Your profile"
                      onBlur={handleBlur("Profile")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.profile && errors.profile && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.profile && errors.profile}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="username">Username</label>
                    </div>
                    <Field
                      disabled
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur("username")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.username && errors.username && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="residence">Location</label>
                    </div>
                    <Field
                      as="select"
                      id="residence"
                      name="residence"
                      value={values.residence}
                      placeholder="State of residence"
                      onBlur={handleBlur("residence")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="North America">North America</option>
                      <option value="South America">South America</option>
                      <option value="Europe">Europe</option>
                      <option value="Asia">Asia</option>
                      <option value="Africa">Africa</option>
                    </Field>

                    {touched.residence && errors.residence && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.residence && errors.residence}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-3">
                    <div>
                      <label htmlFor="photo"> Upload profile picture</label>
                    </div>
                    <input
                      id="photo"
                      name="photo"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("photo", event.currentTarget.files[0]);
                        setUploadedPic(event.currentTarget.files[0]);
                      }}
                    />
                    {uploadedPic && (
                      <img
                        className="my-2"
                        src={URL.createObjectURL(uploadedPic)}
                        class="img-fluid mt-2"
                        width={200}
                        height={100}
                        id="output_image"
                      />
                    )}
                    {updateItem.photo !== "" && (
                      <img
                        className="my-2"
                        src={`${apiUrl}/${updateItem.photo}`}
                        class="img-fluid mt-2"
                        width={200}
                        height={100}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <div>
                      <label htmlFor="photofull"> Upload full picture</label>
                    </div>
                    <input
                      id="photofull"
                      name="photofull"
                      type="file"
                      onChange={(event) => {
                        setFieldValue(
                          "photofull",
                          event.currentTarget.files[0]
                        );
                        setUploadedPicFull(event.currentTarget.files[0]);
                      }}
                    />
                    {uploadedPicFull && (
                      <img
                        className="my-2"
                        src={URL.createObjectURL(uploadedPicFull)}
                        class="img-fluid mt-2"
                        width={200}
                        height={100}
                        id="output_image"
                      />
                    )}
                    {updateItem.full_photo !== "" &&
                      updateItem.full_photo !== null &&
                      updateItem.full_photo !== undefined && (
                        <img
                          className="my-2"
                          src={`${apiUrl}/${updateItem.full_photo}`}
                          class="img-fluid mt-2"
                          width={150}
                          height={250}
                        />
                      )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="facebooklink">Facebook profile</label>
                    </div>
                    <Field
                      id="facebooklink"
                      name="facebooklink"
                      value={values.facebooklink}
                      onBlur={handleBlur("facebooklink")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.facebooklink && errors.facebooklink && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.facebooklink && errors.facebooklink}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="twitterlink">Twitter profile</label>
                    </div>
                    <Field
                      id="twitterlink"
                      name="twitterlink"
                      value={values.twitterlink}
                      onBlur={handleBlur("twitterlink")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.twitterlink && errors.twitterlink && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.twitterlink && errors.twitterlink}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="instagramlink">Instagram profile</label>
                    </div>
                    <Field
                      id="instagramlink"
                      name="instagramlink"
                      value={values.instagramlink}
                      onBlur={handleBlur("instagramlink")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.instagramlink && errors.instagramlink && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.instagramlink && errors.instagramlink}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="linkedinlink">Linkedin profile</label>
                    </div>
                    <Field
                      id="linkedinlink"
                      name="linkedinlink"
                      value={values.linkedinlink}
                      onBlur={handleBlur("linkedinlink")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.linkedinlink && errors.linkedinlink && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.linkedinlink && errors.linkedinlink}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="youtubelink">Youtube profile</label>
                    </div>
                    <Field
                      id="youtubelink"
                      name="youtubelink"
                      value={values.youtubelink}
                      onBlur={handleBlur("youtubelink")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.youtubelink && errors.youtubelink && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.youtubelink && errors.youtubelink}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                buttonType="outline"
                onClick={handleSubmit}
                title="Update"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className="btn btn-primary"
              >
                Update
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateSignupForm;
