import React, { useContext } from "react";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { SiteContext } from "../context";

const SocialIcons = () => {
  const { siteconfig } = useContext(SiteContext);

  return (
    <div class="h-full fixed left-1 p-2 grid gap-4 content-center ...">
      {siteconfig.slfacebook !== "" &&
        siteconfig.slfacebook !== null &&
        siteconfig.slfacebook !== undefined && (
          <div>
            <a
              className="text-decoration-none text-dark"
              href={siteconfig.slfacebook}
              target="_blank"
            >
              <FaFacebookF />
            </a>
          </div>
        )}
      {siteconfig.sltwitter !== "" &&
        siteconfig.sltwitter !== null &&
        siteconfig.sltwitter !== undefined && (
          <div>
            <a
              className="text-decoration-none text-dark"
              href={siteconfig.sltwitter}
              target="_blank"
            >
              <BsTwitter />
            </a>
          </div>
        )}
      {siteconfig.slinstagram !== "" &&
        siteconfig.slinstagram !== null &&
        siteconfig.slinstagram !== undefined && (
          <div>
            <a
              className="text-decoration-none text-dark"
              href={siteconfig.slinstagram}
              target="_blank"
            >
              <BsInstagram />
            </a>
          </div>
        )}
      {siteconfig.slyoutube !== "" &&
        siteconfig.slyoutube !== null &&
        siteconfig.slyoutube !== undefined && (
          <div>
            <a
              className="text-decoration-none text-dark"
              href={siteconfig.slyoutube}
              target="_blank"
            >
              <FaYoutube />
            </a>
          </div>
        )}
      <div style={{ textOrientation: "mixed", writingMode: "vertical-rl" }}>
        THE ANCESTORS
      </div>
    </div>
  );
};

export default SocialIcons;
