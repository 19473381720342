import { BsHouse, BsCarFront } from "react-icons/bs";

export const portflioCategories = [
  {
    name: "Hospitality",
    slug: "hospitality",
  },
  {
    name: "Automobiles",
    slug: "automobiles",
  },
  {
    name: "Legal",
    slug: "legal",
  },
  {
    name: "Software Development",
    slug: "software-development",
  },
  {
    name: "Banking and Finance",
    slug: "baking-finance",
  },
  {
    name: "Fashion",
    slug: "fashion",
  },
  {
    name: "Energy and Power",
    slug: "energy-power",
  },
  {
    name: "Medical",
    slug: "medical",
  },
  {
    name: "Transportation",
    slug: "transportation",
  },
  {
    name: "Events and Entertainment",
    slug: "events-entertainment",
  },
  {
    name: "ICT",
    slug: "ict",
  },
  {
    name: "Fashion",
    slug: "fashion",
  },
  {
    name: "Oil and gas",
    slug: "oil-gas",
  },
  {
    name: "Building and Construction",
    slug: "building-construction",
  },
  {
    name: "News and Publishing",
    slug: "news-publishing",
  },
  {
    name: "Sports",
    slug: "sports",
  },
];
