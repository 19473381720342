import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context";

const ProfileDisplay = ({ user, handleUpdate }) => {
  const { apiUrl } = useContext(SiteContext);

  return (
    <div>
      <div className="container">
        <h3 className="my-2">
          Profile{" "}
          <span className="px-2">
            <button
              className="btn btn-primary"
              onClick={() => handleUpdate(user)}
            >
              <i className="bi bi-pencil-square text-dark"></i>
            </button>
          </span>
        </h3>

        <div className="container p-3">
          <div className="row my-1">
            <div className="col-md-3 p-2">
              <img
                src={`${apiUrl}/${user.photo}`}
                height={100}
                width={100}
                alt="picture"
              />
            </div>
            <div className="col-md-9 p-2">
              <img
                src={`${apiUrl}/${user.full_photo}`}
                height={300}
                width={200}
                alt="picture"
              />
            </div>
          </div>
          <div className="row row my-1">
            <div className="col-md-3 p-2">Name</div>
            <div className="col-md-9 p-2">{user.name}</div>
          </div>
          <div className="row row my-1">
            <div className="col-md-3 p-2">User role</div>
            <div className="col-md-9 p-2">{user.role}</div>
          </div>{" "}
          <div className="row my-1">
            <div className="col-md-3 p-2">Username</div>
            <div className="col-md-9 p-2">{user.username}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Phone</div>
            <div className="col-md-9 p-2">{user.phone}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Profile</div>
            <div className="col-md-9 p-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: user?.profile.replace(/(\r\n|\r|\n)/g, "<br />"),
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 p-2">Email</div>
            <div className="col-md-9 h6 p-2">{user.email}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Birthday</div>
            <div className="col-md-9 h6 p-2">{user.dob}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Membership type</div>
            <div className="col-md-9 h6 p-2 capitalize">
              {user.member_type} member
            </div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Membership role</div>
            <div className="col-md-9 h6 p-2 capitalize">
              {user.member_role} {user.member_position}
            </div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Location</div>
            <div className="col-md-9 h6 p-2 capitalize">
              {user.location_country}
            </div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Facebook</div>
            <div className="col-md-9 h6 p-2">{user.facebook_link}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Twitter</div>
            <div className="col-md-9 h6 p-2">{user.twitter_link}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Instagram</div>
            <div className="col-md-9 h6 p-2">{user.instagram_link}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Linkedin</div>
            <div className="col-md-9 h6 p-2">{user.linkedin_link}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Youtube</div>
            <div className="col-md-9 h6 p-2">{user.youtube_link}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDisplay;
