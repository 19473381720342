import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  category: Yup.string().label("category").required(),
  title: Yup.string().label("Title").required(),
});

const AdminProfileProject = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [items, setItems] = useState([]);
  const [members, setMembers] = useState([]);
  const [memberSelected, setMemberSelected] = useState({});
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getMembers();
    getProjects();
  }, []);

  const getProjects = () => {
    fetch(`${apiUrl}/api/profile_portfolio.php?action=fetchall`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMembers = () => {
    fetch(`${apiUrl}/api/users.php?action=fetchmembers`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMembers(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (userAction === "create") {
      const docType = values.photo.name.split(".").pop();
      const docExtenstions = ["png", "jpeg", "jpg"];

      if (!docExtenstions.includes(docType)) {
        alert("Only PNG, JPEG and JPG files are allowed");
        setSubmitting(false);
        return;
      }
    }

    let filtered = members.filter((item) => item.id === values.member);

    // alert(JSON.stringify(filtered));
    // setSubmitting(false);
    // return;

    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("title", values.title);
    formData.append("photo", values.photo);
    formData.append("about", values.about);
    formData.append("client", values.client);
    formData.append("year", values.year);
    formData.append("mid", filtered[0].id);
    formData.append("mname", filtered[0].name);
    formData.append("username", userData && userData.username);
    formData.append("role", userData && userData.role);
    formData.append("id", values.id);
    formData.append("action", userAction);

    const sendType = "post";

    return fetch(`${apiUrl}/api/profile_portfolio.php`, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getProjects();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 30000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setUploadedPic(null);
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    handleMember(item.portmemid);
    setShowModal(true);
  };

  const handleDelete = (item) => {
    setItemSelected(item);
    setShowDeleteModal(true);
  };

  const doDelete = () => {
    let formData = new FormData();

    formData.append("id", itemSelected.portid);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/profile_portfolio.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          getProjects();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMember = (id) => {
    setMemberSelected({});
    let filtered = members.filter((item) => item.id === id);
    setMemberSelected(filtered[0]);
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doDelete()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "10%",
            right: isMobile ? 0 : "10%",
            width: isMobile ? "100%" : "80%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem ? updateItem.portid : "",
              member: updateItem ? updateItem.portmemid : "",
              category: updateItem ? updateItem.portcat : "",
              title: updateItem ? updateItem.porttitle : "",
              photo: updateItem ? updateItem.portphoto : "",
              about: updateItem ? updateItem.portdesc : "",
              client: updateItem ? updateItem.portclient : "",
              year: updateItem ? updateItem.portyear : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="container my-2">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="member">Member</label>
                      </div>
                      {userAction === "create" ? (
                        <Field
                          as="select"
                          id="member"
                          name="member"
                          value={values.member}
                          onBlur={handleBlur("member")}
                          // onChange={(event) => {
                          //   handleMember(event.target.value);
                          // }}
                          // onChangeText={handleChange("member")}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        >
                          <option value="">Select...</option>
                          {members.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Field>
                      ) : (
                        <div>{updateItem.portmemname} </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="category">Category</label>
                      </div>
                      <Field
                        as="select"
                        id="category"
                        name="category"
                        value={values.category}
                        onBlur={handleBlur("category")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      >
                        <option value="">Select...</option>
                        {portflioCategories.map((item, i) => {
                          return (
                            <option key={i} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Field>

                      {touched.category && errors.category && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.category && errors.category}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="title">Title</label>
                      </div>
                      <Field
                        id="title"
                        name="title"
                        value={values.title}
                        placeholder="Project title"
                        onBlur={handleBlur("title")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.title && errors.title && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.title && errors.title}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="about">Description</label>
                      </div>
                      <Field
                        multiline
                        id="about"
                        name="about"
                        value={values.about}
                        placeholder="About document"
                        onBlur={handleBlur("about")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.about && errors.about && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.about && errors.about}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="client">Client</label>
                      </div>
                      <Field
                        id="client"
                        name="client"
                        value={values.client}
                        placeholder="Client"
                        onBlur={handleBlur("client")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.client && errors.client && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.client && errors.client}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="year">Year</label>
                      </div>
                      <Field
                        multiline
                        id="year"
                        name="year"
                        value={values.year}
                        placeholder="Year"
                        onBlur={handleBlur("year")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.year && errors.year && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.year && errors.year}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-3">
                      <div>
                        <label htmlFor="photo">
                          Upload picture (png, jpeg, jpg)
                        </label>
                      </div>
                      <input
                        id="photo"
                        name="photo"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("photo", event.currentTarget.files[0]);
                          setUploadedPic(event.currentTarget.files[0]);
                        }}
                      />
                      {uploadedPic && (
                        <img
                          className="my-2"
                          src={URL.createObjectURL(uploadedPic)}
                          class="img-fluid mt-2"
                          width={200}
                          height={100}
                          id="output_image"
                        />
                      )}
                      {updateItem.portphoto !== "" &&
                        updateItem.portphoto !== undefined &&
                        updateItem.portphoto !== null && (
                          <img
                            className="my-2"
                            src={`${apiUrl}/${updateItem.portphoto}`}
                            class="h-12 w-12 rounded mt-2"
                          />
                        )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title={userAction}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary capitalize"
                  >
                    {userAction}
                  </button>

                  <button
                    onClick={() => setShowModal(false)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>User Profile Projects</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-3">Project</div>
                    <div className="col-3">Client</div>
                    <div className="col-3">User</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-3">
                          <img
                            className="h-12 w-12 rounded-full mr-2"
                            src={`${apiUrl}/${item.portphoto}`}
                            alt="logo"
                          />
                          {item.porttitle} ({item.portcat})
                        </div>
                        <div className="col-3">
                          {item.portclient} ({item.portyear})
                        </div>
                        <div className="col-3">{item.portmemname}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminProfileProject);
