import React from "react";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 4,
  3000: 5,
  2000: 4,
  1200: 3,
  500: 2,
  350: 1,
};

const breakpointColumnsObjMembers = {
  default: 4,
  3000: 8,
  2000: 7,
  1200: 5,
  800: 4,
  500: 3,
  300: 2,
};

const MasonryLayout = ({ images, handleItemSelected, apiUrl, type, role }) => (
  <Masonry
    className="flex animate-slide-fwd"
    breakpointCols={
      type === "photos" ? breakpointColumnsObj : breakpointColumnsObjMembers
    }
  >
    {images?.map((item, i) => (
      <div key={i} className="m-2">
        <div
          className="relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out cursor-pointer"
          onClick={() => handleItemSelected && handleItemSelected(i)}
        >
          <img
            className={
              type === "photos" ? "rounded w-full" : "rounded-full w-full"
            }
            src={
              type === "photos"
                ? `${apiUrl}/${item.gphoto}`
                : `${apiUrl}/${item.photo}`
            }
            alt={type === "photos" ? item.gdesc : item.name}
          />
          {type === "members" && (
            <div className="text-center">
              <h6 className="py-2 my-1 text-xs">{item.name}</h6>
              {role !== "" && <div>{item.member_position}</div>}
            </div>
          )}
        </div>
      </div>
    ))}
  </Masonry>
);

export default MasonryLayout;
