import React, { useState, useEffect, useContext } from "react";
import { ProfileDisplay, DashboardMenu, AppLayout } from "../components";
import { UpdateProfileModal } from "../modals";
import { useParams } from "react-router-dom";
import moment from "moment";
import { SiteContext } from "../context";

const DocumentRepository = () => {
  const { apiUrl, setUserData } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [documents, setDocuments] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    fetch(`${apiUrl}/api/org_documents.php?action=fetchall`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setDocuments(res.rows);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              {msg !== "" ? (
                <div className="my-4">
                  <span className="p-2 my-2 border border-info text-dark">
                    {msg}
                  </span>
                </div>
              ) : null}
              {errorMsg !== "" ? (
                <div className="my-4">
                  <span className="p-2 my-2 border border-danger text-danger">
                    {errorMsg}
                  </span>
                </div>
              ) : null}

              <div>
                {documents.length > 0 && (
                  <div className="row">
                    {documents.map((item, i) => {
                      return (
                        <div key={i} className="flex col-md-6 my-2">
                          <div className="flex flex-col justify-between content-between border border-width-2 p-2 h-[250px] rounded">
                            <div className="p-2">
                              <h2>{item.reptitle}</h2>
                              {item.repabout}
                              <p>
                                <a
                                  href={`${apiUrl}/${item.repdoc}`}
                                  target="_blank"
                                >
                                  View {item.reptitle}
                                </a>
                              </p>
                            </div>
                            <div className="p-2">
                              <div className="flex justify-between items-center">
                                <p>{item.repcat}</p>
                                <p>
                                  {moment(item.reppublished).format(
                                    "Do, MMM YYYY"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(DocumentRepository);
