import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { DashboardMenu, AppLayout } from "../components";
import { SiteContext } from "../context";

const SiteConfig = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { apiUrl, formatBalance } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [sitedetails, setSitedetails] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getSitedetails();
  }, []);

  const getSitedetails = () => {
    fetch(
      `${apiUrl}/api/siteupdate.php?action=fetchconfig&role=${userData.role}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setSitedetails(res.rows);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (sitedetail) => {
    navigate("/adminsiteupdate", { state: { sitedetail: sitedetail } });
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>Site Config</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              {sitedetails && (
                <div className="container p-3">
                  {sitedetails.map((sitedetail, i) => {
                    return (
                      <div key={i} className="row py-1">
                        <div className="col-10">
                          <img
                            className="img-fluid my-1"
                            src={`${apiUrl}/${sitedetail.shoplogo}`}
                            height={100}
                            width={200}
                            alt={sitedetail.shopname}
                          />
                          <p>Site name: {sitedetail.shopname}</p>
                          <p>
                            Description: <br />
                            {sitedetail.shopdescription}
                          </p>
                          <p>
                            Opening balance:{" "}
                            {formatBalance(sitedetail.opening_bal)}
                          </p>
                          <p>Phone: {sitedetail.shopphone}</p>
                          <p>Email sender: {sitedetail.shopemailname}</p>
                          <p>Email address: {sitedetail.shopemailaddress}</p>
                          <p>
                            Order email address: {sitedetail.shoporderemail}
                          </p>
                          <p>Address: {sitedetail.shopaddress}</p>
                          <p>
                            State: {sitedetail.shopstate},{" "}
                            {sitedetail.shopcountry}
                          </p>
                          <p>Meta key: {sitedetail.shmetakey}</p>
                          <p>Meta description: {sitedetail.shmetadesc}</p>
                          <p>Facebook page Url: {sitedetail.slfacebook}</p>
                          <p>Twitter page Url: {sitedetail.sltwitter}</p>
                          <p>Instagram Url: {sitedetail.slinstagram}</p>
                          <p>Youtube Url: {sitedetail.slyoutube}</p>
                          <p>
                            Last updated:{" "}
                            {moment(sitedetail.updatedAt).format(
                              "Do, MMM YYYY h:mma"
                            )}
                          </p>
                        </div>
                        <div className="col-2">
                          <button onClick={() => handleUpdate(sitedetail)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(SiteConfig);
