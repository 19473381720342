import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHeading } from "../utils";
import { FaTimes } from "react-icons/fa";

const MemberProducts = ({ apiUrl, formatBalance, items, linkProfile }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [selected, setSelected] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [cats, setCats] = useState([]);

  useEffect(() => {
    handleCats();
  }, [items]);

  const handleCats = () => {
    let unique = [];
    for (let i = 0; i < items.length; i++) {
      if (unique.indexOf(items[i].category) === -1) {
        unique.push(items[i].category);
      }
    }

    setCats(unique);
  };

  const getProducts = (id) => {
    setSelected(id);

    if (id === "all") {
      return setFilteredItems(items);
    }

    let filtered = items.filter((item) => item.category === id);
    setFilteredItems(filtered);
  };

  return (
    <div className="relative w-full w-max-[1300px] mx-auto py-4">
      <div className="flex flex-col justify-center items-center my-3 py-3">
        <h1>PRODUCTS</h1>
        <div
          style={{ width: 60 }}
          className="border-bottom border-4 border-success text-start"
        />
      </div>

      <div>
        <button
          className={
            selected === "all"
              ? "bg-gray-600 text-white py-1 px-2 rounded m-1"
              : "bg-gray-200 py-1 px-2 rounded m-1"
          }
          onClick={() => getProducts("all")}
        >
          All
        </button>{" "}
        {cats.map((item, i) => {
          return (
            <button
              className={
                selected === item
                  ? "bg-gray-600 text-white py-1 px-2 rounded m-1"
                  : "bg-gray-200 py-1 px-2 rounded m-1"
              }
              onClick={() => getProducts(item)}
            >
              {item}
            </button>
          );
        })}
      </div>

      <div className="row p-3">
        {filteredItems.map((item, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setItemSelected(item);
                setShowDetail(true);
              }}
              className="col-md-3 p-2 m-1 hover:grayscale cursor-pointer"
            >
              <div className="text-center overflow-y-hidden">
                <img
                  src={`${apiUrl}/${item.picture}`}
                  height="auto"
                  width="100%"
                  className="rounded min-h-[190px]"
                  alt={item.name}
                />
              </div>
              <div className="flex justify-between items-center py-2 mt-2">
                <p>{item.name}</p>
                <p>{formatBalance(item.price)}</p>
              </div>
            </div>
          );
        })}
      </div>

      {showDetail && (
        <div className="absolute h-full z-100 bg-white top-0 bottom-0 left-0 right-0 p-3">
          <div className="row">
            <div className="col-6 text-right h1">
              <div className="flex flex-col justify-center items-center my-2 py-2">
                <h1>PRODUCTS</h1>
                <div
                  style={{ width: 60 }}
                  className="border-bottom border-4 border-success text-start"
                />
              </div>
            </div>
            <div className="col-6 flex justify-end">
              <FaTimes
                size={40}
                className="cursor-pointer"
                onClick={() => setShowDetail(false)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="text-center h-[300px] w-[100%] overflow-y-hidden">
                <img
                  src={`${apiUrl}/${itemSelected.picture}`}
                  height="290px"
                  width="auto"
                  className="rounded"
                  alt={itemSelected.name}
                />
              </div>
            </div>
            <div className="col-md-6">
              <h5>{itemSelected.name}</h5>
              <p>{itemSelected.description}</p>
              <p>{formatBalance(itemSelected.price)}</p>
              <p>Qty: {itemSelected.qty}</p>
              {linkProfile && (
                <Link
                  className="text-decoration-none text-white cursor-pointer bg-success rounded p-2"
                  to={`/business-profile/${
                    itemSelected.prodmemid
                  }/${itemSelected.prodmemname
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                >
                  Contact the person
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberProducts;
