import React, { useState, useEffect, useContext } from "react";
import { EventsHome, PhotoGalleryHome } from "./";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  VideoDisplay,
  AppLayout,
  NewsBox,
  NGStats,
  TimeLeft,
  Testimonials,
} from "../components";
import { logo } from "../constants";
import { removeTags, getHeading } from "../utils";
import Seo from "./Seo";

const Home = () => {
  const {
    userData,
    apiUrl,
    loading,
    siteconfig,
    homeNews,
    homeEvents,
    slugify,
    isLoggedOn,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [featured, setFeatured] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [excos, setExcos] = useState([]);

  useEffect(() => {
    getAllVideos();
    getCurrentExco();
  }, []);

  const getAllVideos = () => {
    setVideoLoading(true);
    return fetch(`${apiUrl}/api/allvideos.php?action=fetch`, {
      method: "get",
    })
      .then(processResponse)
      .then(async (res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          await setAllVideos(data.videos);
          setVideoLoading(false);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getCurrentExco = () => {
    return fetch(`${apiUrl}/api/users.php?action=currentexco`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setExcos(res.rows);
        } else if (res.status === 400) {
          console.log(res.msg);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const yId = (urllink) => {
    let vid = urllink.substring(31, 42);
    return vid;
  };

  const processResponse = (response) => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div className="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="w-full py-3 my-1" style={{ maxWidth: "1300px" }}>
        <div className="row bg-slate-100">
          <div className="col-md-8 p-2">
            <NewsBox apiUrl={apiUrl} news={homeNews?.slice(0, 4)} num="4" />
          </div>
          <div className="col-md-4 p-2 bg-white">
            <div className="mb-3 p-4 bg-green-900 justify-start text-lg text-white rounded">
              {/* <img
                src={logo}
                className="h-[300px] w-[300px] rounded"
                alt="logo"
              /> */}
              This group was created to encourage camaraderie, interaction,
              networking, sharing of ideas and fellowship. To organize the
              Class' representation among other alumni of Command Secondary
              School Abakaliki or any other body/group and to collectively give
              back to our Alma mater.
            </div>
            <div className="my-1 bg-black text-white text-center rounded">
              <h4 className="py-2">Countdown to the next AGM</h4>
              <TimeLeft dd={"2023-10-27"} color="#333333" />
            </div>
          </div>
        </div>
      </div>

      <div className="my-3 py-3">
        <NGStats />
      </div>

      <div style={{ backgroundColor: "#000000" }} classNameName="my-2 py-3">
        <div
          style={{ overflowX: "scroll", maxWidth: "1300px" }}
          className="container"
        >
          {videoLoading && (
            <div className="d-flex justify-content-center align-content-center my-5">
              <div className="spinner-border text-secondary" role="status" />
            </div>
          )}
          {allVideos.length > 0 && (
            <div className="row">
              {allVideos.slice(0, 3).map((item, i) => {
                const opts = {
                  height: "240",
                  width: "100%",
                };

                return (
                  <div key={i} className="col-md-4 p-3">
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/video/${item.videntryid}/${slugify(item.vidtitle)}`}
                    >
                      <VideoDisplay
                        url={`${item.vidurl}`}
                        title={item.vidtitle}
                        showTitle
                        opts={opts}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="my-2 py-2">
        <EventsHome homeEvents={homeEvents && homeEvents} isMobile={isMobile} />
      </div>

      {excos.length > 0 && (
        <div className="container">
          <div className="bg-black p-3">
            <h3 className="text-white my-2 py-2">CURRENT EXCO</h3>
            <div className="row justify-content-center align-items-center">
              {excos.map((member, i) => {
                return (
                  <div className="col-md-4 col-6">
                    <div className="md:max-w-[250px] mx-auto flex flex-col justify-center items-center rounded text-white cursor-pointer">
                      <div className="rounded-full hover:bg-red-300 bg-slate-500 p-2">
                        <img
                          src={`${apiUrl}/${member.photo}`}
                          alt={member.name}
                          className="h-[100px] w-[100px] rounded-full"
                        />
                      </div>
                      <h5 className="py-1 text-sm">{member.name}</h5>
                      <h6 className="my-0 py-1 text-sm">
                        {member.member_position}
                      </h6>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="my-3 py-3">
        <PhotoGalleryHome />
      </div>
    </div>
  );
};

export default AppLayout(Home);
