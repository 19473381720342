import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  category: Yup.string().label("Category").required(),
  name: Yup.string()
    .label("Name")
    .required()
    .min(4, "Must have at least 4 characters"),
  description: Yup.string()
    .label("Description")
    .required()
    .min(5, "Must have at least 11 characters"),
  price: Yup.string().label("Price").required("Please enter amount"),
  qty: Yup.string().label("Quantity").required("Please enter the quatity"),
  status: Yup.string().label("Status").required(),
});

const UserProfileProduct = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [items, setItems] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    let formData = new FormData();

    formData.append("username", userData && userData.username);
    formData.append("action", "fetchalluser");

    fetch(`${apiUrl}/api/profile_products.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (userAction === "create") {
      const docType = values.picture.name.split(".").pop();
      const docExtenstions = ["png", "jpeg", "jpg"];

      if (!docExtenstions.includes(docType)) {
        alert("Only PNG, JPEG and JPG files are allowed");
        setSubmitting(false);
        return;
      }
    }

    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("catslug", slugify(values.category));
    formData.append("name", values.name);
    formData.append("nameslug", slugify(values.name));
    formData.append("sku", values.sku);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("promo", values.promo !== "" ? values.promo : 0);
    formData.append("qty", values.qty);
    formData.append("status", values.status ? 1 : 0);
    formData.append("picture", values.picture);
    formData.append("picture2", values.picture2);
    formData.append("picture3", values.picture3);
    formData.append("mid", userData && userData.id);
    formData.append("mname", userData && userData.name);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("product", values.id);
    formData.append("action", userAction);

    const sendType = "post";

    return fetch(`${apiUrl}/api/profile_products.php`, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getProducts();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 30000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setUploadedPic(null);
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    setShowModal(true);
  };

  const handleDelete = (item) => {
    setItemSelected(item);
    setShowDeleteModal(true);
  };

  const doDelete = () => {
    let formData = new FormData();

    formData.append("product", itemSelected.id);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/profile_products.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          getProducts();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doDelete()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "10%",
            right: isMobile ? 0 : "10%",
            width: isMobile ? "100%" : "80%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem.id ? updateItem.id : "",
              member: updateItem ? updateItem.prodmemid : "",
              category: updateItem.category ? updateItem.category : "",
              name: updateItem.name ? updateItem.name : "",
              sku: updateItem.sku ? updateItem.sku : "",
              description: updateItem.description ? updateItem.description : "",
              price: updateItem.price ? updateItem.price : "",
              promo: updateItem.promo ? updateItem.promo : "",
              qty: updateItem.qty ? updateItem.qty : "",
              status: updateItem.status == 1 ? true : false,
              picture: updateItem.picture ? updateItem.picture : "",
              picture2: updateItem.picture2 ? updateItem.picture2 : "",
              picture3: updateItem.picture3 ? updateItem.picture3 : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} encype="multipart/form-data">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="member">Member</label>
                      </div>
                      {userAction === "update" && (
                        <div>{updateItem.prodmemname} </div>
                      )}{" "}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="category">Category</label>
                      </div>
                      <Field
                        as="select"
                        id="category"
                        name="category"
                        value={values.category}
                        placeholder="Category"
                        onBlur={handleBlur("category")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      >
                        <option value="">Select...</option>
                        {portflioCategories.map((item, i) => {
                          return (
                            <option key={i} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Field>

                      {touched.category && errors.category && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.category && errors.category}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-2">
                      <div>
                        <label htmlFor="name">Name</label>
                      </div>
                      <Field
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Name"
                        onBlur={handleBlur("name")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />
                      {touched.name && errors.name && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.name && errors.name}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="description">Description</label>
                      </div>
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        value={values.description}
                        placeholder="Description"
                        onBlur={handleBlur("description")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                        rows="5"
                      />
                      {touched.description && errors.description && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.description && errors.description}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-3">
                      <div>
                        <label htmlFor="picture"> Picture</label>
                      </div>
                      <input
                        id="picture"
                        name="picture"
                        type="file"
                        onChange={(event) => {
                          setFieldValue(
                            "picture",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                    </div>

                    {updateItem && (
                      <img
                        src={`${apiUrl}/${updateItem.picture}`}
                        height={70}
                        width={70}
                        alt="picture"
                      />
                    )}

                    <div className="form-group my-3">
                      <div>
                        <label htmlFor="picture2"> Picture 2</label>
                      </div>
                      <input
                        id="picture2"
                        name="picture2"
                        type="file"
                        onChange={(event) => {
                          setFieldValue(
                            "picture2",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                    </div>

                    {updateItem.picture2 && (
                      <img
                        src={`${apiUrl}/${updateItem.picture2}`}
                        height={70}
                        width={70}
                        alt="picture2"
                      />
                    )}

                    <div className="form-group my-3">
                      <div>
                        <label htmlFor="picture3"> Picture 3</label>
                      </div>
                      <input
                        id="picture3"
                        name="picture3"
                        type="file"
                        onChange={(event) => {
                          setFieldValue(
                            "picture3",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                    </div>

                    {updateItem.picture3 && (
                      <img
                        src={`${apiUrl}/${updateItem.picture3}`}
                        height={70}
                        width={70}
                        alt="picture"
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="price">Price</label>
                      </div>
                      <Field
                        id="price"
                        name="price"
                        value={values.price}
                        placeholder="Price"
                        onBlur={handleBlur("price")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />
                      {touched.price && errors.price && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.price && errors.price}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="promo">Promo</label>
                      </div>
                      <Field
                        id="promo"
                        name="promo"
                        value={values.promo}
                        placeholder="Promo"
                        onBlur={handleBlur("promo")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />
                      {touched.promo && errors.promo && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.promo && errors.promo}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="sku">SKU</label>
                      </div>
                      <Field
                        id="sku"
                        name="sku"
                        value={values.sku}
                        placeholder="SKU"
                        onBlur={handleBlur("sku")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />
                      {touched.sku && errors.sku && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.sku && errors.sku}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="qty">Quantity</label>
                      </div>
                      <Field
                        id="qty"
                        name="qty"
                        value={values.qty}
                        placeholder="Quantity"
                        onBlur={handleBlur("qty")}
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.qty && errors.qty && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.qty && errors.qty}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group my-1 py-2">
                      <div>
                        <label htmlFor="status">In stock?</label>{" "}
                        <Field type="checkbox" name="status" />{" "}
                        {values.status ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  buttonType="outline"
                  onClick={handleSubmit}
                  title="Update"
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  className="btn btn-primary my-2"
                >
                  Update
                </button>

                <button
                  onClick={() => setShowModal(false)}
                  title="Cancel"
                  className="btn btn-secondary mx-2"
                >
                  Cancel
                </button>
              </form>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>User Profile Products</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-3">Product</div>
                    <div className="col-3">Description</div>
                    <div className="col-3">User</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="md:flex col-3">
                          <img
                            className="h-12 w-12 rounded-full mr-2"
                            src={`${apiUrl}/${item.picture}`}
                            alt="logo"
                          />
                          <div>
                            {item.name}
                            <p className="py-0 my-0">{item.price}</p>
                            {item.category}
                          </div>
                        </div>
                        <div className="col-3">
                          {item.description}
                          <p>Qty: {item.qty}</p>
                        </div>
                        <div className="col-3">{item.prodmemname}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(UserProfileProduct);
