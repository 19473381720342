import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { getHeading } from "../utils";
import CategoriesFaqsMenu from "./CategoriesFaqsMenu";

const MemberPortfolio = ({ apiUrl, items, linkProfile }) => {
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [showDetail, setShowDetail] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [cats, setCats] = useState([]);

  useEffect(() => {
    handleCats();
  }, [items]);

  const handleCats = () => {
    let unique = [];
    for (let i = 0; i < items.length; i++) {
      if (unique.indexOf(items[i].portcat) === -1) {
        unique.push(items[i].portcat);
      }
    }

    setCats(unique);
  };

  const getPortfolio = (id) => {
    setSelected(id);

    if (id === "all") {
      return setFilteredItems(items);
    }

    let filtered = items.filter((item) => item.portcat === id);
    setFilteredItems(filtered);
  };

  return (
    <div className="relative w-full w-max-[1300px] mx-auto py-3">
      <div className="row bg-gray-100 px-3">
        <div className="flex flex-col justify-center items-center my-3 py-3">
          <h1>PORTFOLIO</h1>
          <div
            style={{ width: 60 }}
            className="border-bottom border-4 border-success text-start"
          />
        </div>
        <div>
          <button
            className={
              selected === "all"
                ? "bg-gray-600 text-white py-1 px-2 rounded m-1"
                : "bg-gray-200 py-1 px-2 rounded m-1"
            }
            onClick={() => getPortfolio("all")}
          >
            All
          </button>{" "}
          {cats.map((item, i) => {
            return (
              <button
                className={
                  selected === item
                    ? "bg-gray-600 text-white py-1 px-2 rounded m-1"
                    : "bg-gray-200 py-1 px-2 rounded m-1"
                }
                onClick={() => getPortfolio(item)}
              >
                {item}
              </button>
            );
          })}
        </div>

        <div className="row p-3">
          {filteredItems.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setItemSelected(item);
                  setShowDetail(true);
                }}
                className="relative col-md-4 p-3 cursor-pointer"
              >
                <div className="flex overflow-y-hidden justify-center items-center hover:bg-green-200 cursor-pointer">
                  <img
                    src={`${apiUrl}/${item.portphoto}`}
                    height="100%"
                    width="100%"
                    className="rounded w-auto duration-300 ease-in-out"
                    alt={item.porttitle}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showDetail && (
        <div className="absolute z-100 bg-white top-0 bottom-0 left-0 right-0 p-3">
          <div className="row">
            <div className="col-md-6 text-right h1">
              <div className="flex flex-col justify-center items-center my-2 py-2">
                <h1>PORTFOLIO</h1>
                <div
                  style={{ width: 60 }}
                  className="border-bottom border-4 border-success text-start"
                />
              </div>
            </div>
            <div className="col-md-6 flex justify-end">
              <FaTimes
                size={40}
                className="cursor-pointer"
                onClick={() => setShowDetail(false)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="text-center h-[300px] w-[100%] overflow-y-hidden">
                <img
                  src={`${apiUrl}/${itemSelected.portphoto}`}
                  height="auto"
                  width="auto"
                  className="rounded"
                  alt={itemSelected.porttitle}
                />
              </div>
            </div>
            <div className="col-md-6">
              <h5>{itemSelected.porttitle}</h5>
              <p>{itemSelected.portdesc}</p>
              <p>Client: {itemSelected.portclient}</p>
              <p>Category: {itemSelected.portcat}</p>
              <p>Year: {itemSelected.portyear}</p>
              {linkProfile && (
                <Link
                  className="text-decoration-none text-white cursor-pointer bg-success rounded p-2"
                  to={`/business-profile/${
                    itemSelected.portmemid
                  }/${itemSelected.portmemname
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                >
                  Contact the person
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberPortfolio;
