import React, { useState, useEffect, useContext } from "react";
import { Pagination, NavigationBar, AppLayout } from "../components";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SiteContext } from "../context";
import { Header, Footer, Seo } from "./";

const Projects = () => {
  let navigate = useNavigate();

  const { apiUrl, pages, siteconfig, userData } = useContext(SiteContext);

  const [contents, setContents] = useState([]);
  const [pageServices, setPageServices] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getServices();
    window.scrollTo(0, 0);
  }, []);

  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchsectionpages&section=Projects`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setContents(res.rows);
          setPageServices(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <Seo
        title={`Projects | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}/projects`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="my-2 container">
        <NavigationBar section={"Projects"} goback={false} />

        <div className="row">
          <div className="col-md-8">
            {contents.length > 0 ? (
              <div className="row">
                {contents.map((content, i) => {
                  return (
                    <div className="p-4 text-left">
                      <Link
                        className="text-decoration-none text-dark text-left"
                        to={`/project/${content.cpagelinkname}`}
                        state={{ service: content }}
                      >
                        <div className="row my-1 py-2 border rounded">
                          <div className="col-4">
                            <img
                              src={`${apiUrl}/${content.cpagephoto}`}
                              height={"100%"}
                              width={"100%"}
                              alt={content.cpagename}
                            />
                          </div>
                          <div className="col-8 p-3">
                            <h4>{content.cpagename}</h4>
                            <div
                              className="py-2"
                              dangerouslySetInnerHTML={{
                                __html: content.cpagecontent
                                  .split(" ", 20)
                                  .join(" "),
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="my-2">No content found</div>
            )}
          </div>
          <div className="col-md-4 bg-light mt-2">
            <h4 className="bg-dark text-white rounded border-bottom p-2">
              Projects
            </h4>
            {pageServices.length > 0 &&
              pageServices.map((page, i) => {
                return (
                  <div
                    key={i}
                    className="text-decoration-none border-bottom my-1"
                  >
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/project/${page.cpagelinkname}`}
                    >
                      <div className="p-2">{page.cpagename}</div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(Projects);
